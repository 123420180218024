import React from 'react'
import { graphql, StaticQuery } from 'gatsby'
import Layout from '~/components/Layout'
import Metadata from '~/components/Metadata'
import ProductPage from '~/components/Product/Page'
import { PDPProvider } from '../components/Product/Page/PDPContext'
import { useAnalytics } from '@chordcommerce/gatsby-theme-autonomy'

const KitTemplate = ({ data }) => {
  const { trackProductViewed } = useAnalytics()

  return (
    <StaticQuery
      query={query}
      render={data => {
        const { kit } = data
        const product = kit?.kitProductDisplayReference
        if (typeof window !== 'undefined') {
          trackProductViewed({ product })
        }
        return (
          <Layout>
            <PDPProvider product={kit.kitProductDisplayReference} kit={kit}>
              <Metadata
                title={kit.name}
                description={kit.shortDescription ? kit.shortDescription : ''}
                image={product.mainImage.fluid.src}
              />
              <ProductPage></ProductPage>
            </PDPProvider>
          </Layout>
        )
      }}
    ></StaticQuery>
  )
}

const query = graphql`
  query KitQuery($slug: String, $locale: String) {
    kit: contentfulKit(slug: { eq: $slug }, node_locale: { eq: $locale }) {
      kitProductDisplayReference {
        ...ProductPageFragment
        theme
      }
      collection {
        slug
        products {
          name
          slug
        }
        kits {
          name
          slug
        }
      }
      ...KitPageFragment
    }
  }
`

export default KitTemplate
